import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class AppService {
  private toastSubject = new BehaviorSubject<any>(null);
  public toast = this.toastSubject.asObservable();
  private dialogSubject = new BehaviorSubject<any>(null);
  public dialog = this.dialogSubject.asObservable();
  private themeSubject = new BehaviorSubject<any>(null);
  public theme = this.themeSubject.asObservable();

  constructor(
    // @Inject(DOCUMENT) private document: Document
  ) {}

  public showToast(message: any) {
    this.toastSubject.next(message);
  }

  public showDialog(dialog: any) {
    this.dialogSubject.next(dialog);
  }

  public showLeavingDialog(url: string) {
    this.showDialog({ 
      acceptIcon:"none",
      acceptButtonStyleClass:"p-button-primary p-button-rounded",
      acceptLabel: 'Continue',
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-secondary p-button-rounded p-button-text",
      rejectLabel: 'Cancel',
      header: `You are leaving Riterz`,
      message: `
        You are about to leave Riterz Platform and navigate to another website.
      `,
      accept: () => {
        window.open(url, '_blank');
      },
      reject: () => {}
    });
  }

  public switchTheme(theme: string) {
    // let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    // if (themeLink) {
    //   themeLink.href = theme + '.css';
    //   this.themeSubject.next(theme);
    //   localStorage.setItem('settings.theme', theme);
    // }
  }

}